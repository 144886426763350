import React from 'react'
import { useSnackbar } from '@/hooks/useSnackbar'
import Snackbar from '@/components/Snackbar'

interface CopyButtonProps {
  data: string
}

export const CopyButton: React.FC<CopyButtonProps> = ({ data }) => {
  const { isActive, message, openSnackbar } = useSnackbar()
  const id = data

  const handleClick = () => {
    const text = document.getElementById(id)?.textContent || 0
    let snackText = 'Error during copying.'

    if (text !== 0) {
      navigator.clipboard.writeText(text)
      snackText = 'Text copied!'
    }

    openSnackbar(snackText)
  }

  return (
    <>
      <button
        onClick={handleClick}
        className="button__reset icon"
        aria-label="copy text"
        title="Copy text"
        type="button"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          fill="black"
          width="48px"
          height="48px"
        >
          <path d="M0 0h24v24H0z" fill="none" />
          <path d="M16 1H4c-1.1 0-2 .9-2 2v14h2V3h12V1zm3 4H8c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h11c1.1 0 2-.9 2-2V7c0-1.1-.9-2-2-2zm0 16H8V7h11v14z" />
        </svg>
      </button>
      <Snackbar isActive={isActive} message={message} />
    </>
  )
}

export default CopyButton
